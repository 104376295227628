<template>
  <div>
    <SubscriptionCharges
      :fstId="`subscriptionTransactions`"
      :tableHeadersPreset="`transaction`"
      paid-only
    />
  </div>
</template>

<script>
import SubscriptionCharges from '@/composites/organization/details/SubscriptionCharges.vue'

export default {
  name: 'ViewOrganizationRevenues',
  components: { SubscriptionCharges },
  data() {
    return {
      fleet: {},
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  async created() {},
}
</script>

<style lang="scss"></style>
